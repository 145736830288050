import axios from 'axios';
import Entity from "./Entity";

export default class Api {

    constructor(drupal) {
        this.drupal = drupal;
    }

    getConfig = async (configName) => {
        const response = await axios.get(this.drupal.getBaseUrl() + `/config/${configName}`);

        return response;
    }

    getEntity = async (entityType, entityBundle, id) => {
        const options = this.getStandardHeaders();

        const response = await axios.get(this.drupal.getBaseUrl() + this.drupal.getJsonApiBase() +`${entityType}/${entityBundle}/${id}`, options);

        return Object.assign(new Entity(), response);
    }

    getEntities = async (entityType, entityBundle, params = {}) => {
        const options = this.getStandardHeaders();
        
        if(Object.keys(params) !== 0 && params.constructor === Object) {
          this.addParametersAsOptions(options, params);
        }

        const response = await axios.get(this.drupal.getBaseUrl() + this.drupal.getJsonApiBase() + `${entityType}/${entityBundle}`, options);

        for(const i in response.data.data) {
            let entity = new Entity();
            entity.data = response.data.data[i];
            entity.data.included = response.data.included;
            response.data.data[i] = entity;
        }

        return response;
    }

    getResponseFromUrl = async (url) => {
        const response = await axios.get(url);

        for(const i in response.data.data) {
            let entity = new Entity();
            entity.data = response.data.data[i]
            entity.data.included = response.data.included;
            response.data.data[i] = entity;
        }

        return response;
    }

    resolvePath = async (params) => {
        const options = this.getStandardHeaders();

        if(this.drupal.domainOverride) {
            params.domain = this.drupal.domainOverride;
        } else {
            params.domain = window.location.host;
        }

        this.addParametersAsOptions(options, params);

        let response = null;

        try {

            response = await axios.get(this.drupal.getBaseUrl() + '/router/translate-path', options);
        } catch(e) {
            response = {
                status: 404
            };
        }

        return response;
    }

    getEntityMetaFromPath = (response) => {
        if(response.data.hasOwnProperty('entity') && response.data.entity instanceof Object) {
            return response.data.entity;
        }

        return {};
    }

    getStandardHeaders = () => {
        return {
            'headers': {
                'Accept': 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json'
            },
        };
    }

    addParametersAsOptions = (options, params) => {
        const entries = Object.entries(params);

        options.params = {};

        for(const [prop, value] of entries) {
            options.params[prop] = value;
        }

        return options;
    }

    hasNext = (links) => {
        if(links instanceof Object 
            && links.hasOwnProperty('next')) 
        {
            return true;
        }

        return false;
    }

    getNextUrl = (links) => {
        if(links instanceof Object && links.hasOwnProperty('next')) {
            return links.next.href;
        }

        return null;
    }

    lookupInclude = (includes, includeId) => {
        const include = includes.find((element) => element.id === includeId);

        return include;
    }

}