import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Include from '../../drupal/Include';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import DefaultHeroItem from '../../components/content/building/DefaultHeroItem/DefaultHeroItem';
import GoogleMap from '../../components/ui/Map/GoogleMap/GoogleMap';
import Meta from '../../components/atoms/Meta/Meta';
import BuildingFactGrid from '../../components/content/building/BuildingFacts/BuildingFactGrid/BuildingFactGrid';
import MiniMenu from '../../components/content/paragraphs/MiniMenu/MiniMenu';

export default class ContentPage extends Component {

  render() {

    let hero = null;
    let content = [];
    let map = null;
    let buildingFacts = null;
    let quickLinks = null;

    if(this.props.entity instanceof Entity) {
      if(this.props.entity.get('field_hero') instanceof Include && this.props.entity.get('field_hero').get('id')) {
        hero = <Paragraph content={this.props.entity.get('field_hero')} building={this.props.building} drupal={this.props.drupal} />;
      } else {
        hero = <DefaultHeroItem building={this.props.building} drupal={this.props.drupal} />;
      }

      if(this.props.building.get('field_customer_information') instanceof Array) {
        buildingFacts = (
            <div className="page-section padded">
                <BuildingFactGrid building={this.props.building} />
            </div>
        );
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        if(!paragraph.get('behavior_settings')?.layout_paragraphs?.parent_uuid) {
          content.push(<Paragraph key={paragraph.get('id')} content={paragraph} drupal={this.props.drupal} />);
        }
      }

      if(this.props.isFront) {
        let places = this.processBuildingPlaces(this.props.building);

        map = (
          <div className="page-section">
              <GoogleMap places={places} defaultZoom={this.props.building.get('property_default_map_zoom_level')} outlet={this.props.outlet} />
          </div>
        );

        if(this.props.building.get('field_customer_quick_links')) {
          quickLinks = <MiniMenu links={this.props.building.get('field_customer_quick_links')} title="Quick Links" />;
        }
        
      }
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
      <div className="page">
        {meta}

        {hero}

        <div className="content-wrapper container">
          <div className="row">
            <div className="page-title col-12">
            </div>
          </div>
          <div className="row">
            <div className="page-content col-12">
              {buildingFacts}
              {quickLinks}
              {content}
            </div>
          </div>

        </div>

        {map}

      </div>
    );
  }

  processBuildingPlaces = (building) => {
    let places = [];

    places.push({
        name: building.get('field_name'),
        lat: building.get('field_geolocation').lat,
        lng: building.get('field_geolocation').lng,
        type: 'building',
        id: building.get('id')
    });

    for(let place of building.get('field_places')) {
        places.push({
            name: place.get('title'),
            lat: place.get('field_geolocation').lat,
            lng: place.get('field_geolocation').lng,
            type: place.get('field_place_type'),
            id: place.get('id')
        });
    }

    return places;
  }

}